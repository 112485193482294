<template>
  <div class="promote">
    <!-- <title-top>商品详情</title-top> -->

    <div class="column w100" v-if="flashSaleProduct.length>0">
      <div class="row flex-between h80 w100">
        <div class="row align-center">
          <div class="font32 fontwb f-1A1A1A ml40 mr25">限时秒杀</div>
          <div class="font26 f-999999">每日好货 抢到赚到</div>
        </div>
        <div class="row" style="margin-right: .44rem;">
          <div class="row flex-center bj font24 f-FCFCFC">距开抢</div>
          <div class="row flex-center bjk font24 f-F62341">
            <van-count-down
              :time="format(flashSaleProduct[0].begin_at,flashSaleProduct[0].end_at)"
            />
          </div>
        </div>
      </div>
      <div class="row flex-wrap" style="margin-left: .44rem;">
        <div
          class="column mr12 mb32"
          style="width:2.933333rem"
          @click="onItem(item)"
          v-for="(item,index) in flashSaleProduct"
          :key="index"
        >
          <img class="goodssize br5" :src="item.image_url" alt />
          <div class="font26 f-1A1A1A ellipsis">{{item.product_name}}</div>
          <div class="row flex-center mt16">
            <div class="row baseline f-F62341 mr16">
              <span class="font32 fontwb">￥{{item.price}}</span>
              <span class="font28 fontwb" v-if="false">9</span>
            </div>
            <span class="msbj font20 f-FCFCFC">秒杀价</span>
          </div>
        </div>
      </div>
      <!-- <div class="row flex-center mt40 mb32">
        <span class="slide bg-999999"></span>
        <span class="slide bg-E0E0E0"></span>
      </div>-->
    </div>
    <div class="bg-FCFCFC" style="height:.266666rem;"></div>

    <div class="column w100" v-if="hotProduct.length>0">
      <div class="row align-center h80 w100">
        <div class="font32 fontwb f-1A1A1A ml40 mr25">热销榜单</div>
        <div class="font26 f-999999">品牌折扣、万人疯抢爆款</div>
      </div>
      <div class="row flex-wrap" style="margin-left: .44rem;">
        <div
          class="column mr12 mb32"
          style="width:2.933333rem"
          v-for="(item,index) in hotProduct"
          :key="index"
          @click="onItem(item)"
        >
          <img class="goodssize br5" :src="item.image_url" alt />
          <div class="font26 f-1A1A1A ellipsis">{{item.product_name}}</div>
          <div class="row flex-center baseline f-F62341">
            <span class="font32 fontwb">￥{{item.price}}</span>
            <span class="font28 fontwb" v-if="false">00</span>
          </div>
        </div>
      </div>
    </div>

    <div class="column w100" v-if="featuredProduct.length>0">
      <div class="row align-center h80 w100">
        <div class="font32 fontwb f-1A1A1A ml40 mr25">好货精选</div>
        <div class="font26 f-999999">精选大牌抢先购</div>
      </div>
      <div class="row flex-wrap" style="margin-left: .44rem;">
        <div
          class="column mr12 mb32"
          style="width:2.933333rem"
          @click="onItem(item)"
          v-for="(item,index) in featuredProduct"
          :key="index"
        >
          <img class="goodssize br5" :src="item.image_url" alt />
          <div class="font26 f-1A1A1A ellipsis">{{item.product_name}}</div>
          <div class="row flex-center baseline f-F62341">
            <span class="font32 fontwb">￥{{item.price}}</span>
            <span class="font28 fontwb" v-if="false">00</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import { promotions } from "network/igou2.js";
import { params } from "@/common/const";
import Vue from "vue";
import { CountDown } from "vant";

Vue.use(CountDown);

export default {
  name: "Promote",
  data() {
    return {
      time: 30 * 60 * 60 * 1000,
      flashSaleProduct: [],
      featuredProduct: [],
      hotProduct: []
    };
  },

  mounted() {
    this._promotions();
  },
  methods: {
    onItem(item) {
      let url = params.debug ? params.h5_test : params.h5_pro;
      //url = 'http://192.168.1.5:8080/';
      url += "zydetail?id=" + item.product_id;
      console.log(item)
      let obj = { type: "h5", url: url };
      if (this.isAndroid()) {
        window.app.cbMessage(JSON.stringify(obj));
      } else if (this.isiOS()) {
        window.webkit.messageHandlers.cbMessage.postMessage(JSON.stringify(obj));
      }
      /*
      this.$router.push({
        path: "/zydetail",
        query: { id: item.product_id }
      });
      */
    },
    isAndroid() {
      let u = navigator.userAgent;
      return u.indexOf("Android") > -1 || u.indexOf("Adr") > -1;
    },
    isiOS() {
      let u = navigator.userAgent;
      return !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/);
    },
    _promotions() {
      promotions().then(res => {
        let list = res.data;
        if (list.length > 0) {
          this.flashSaleProduct = list.filter(
            t => t.type === "FlashSaleProduct"
          ); //限时购
          this.featuredProduct = list.filter(t => t.type === "FeaturedProduct"); //精选商品
          this.hotProduct = list.filter(t => t.type === "HotProduct"); //热销商品
        }
      });
    },
    format(start, end) {
      var t1 = new Date().getTime();
      var t2 = new Date(end).getTime();
      return t2 - t1;
    }
  }
};
</script>
<style  scoped>
.mr12 {
  margin-right: 0.16rem;
}
.bj {
  height: 0.56rem;
  width: 1.2rem;
  background-color: #f62341;
}
.van-count-down {
  color: #f62341 !important;
}
.bjk {
  width: 2.16rem;
  height: 0.506667rem;
  border: 0.013333rem solid #f62341;
}
.msbj {
  border-radius: 0 0.213333rem 0.213333rem 0.106667rem;
  background-color: #f62341;
  padding: 0 0.106667rem 0 0.106667rem;
}
.goodssize {
  width: 2.933333rem;
  height: 3.013333rem;
}
.slide {
  height: 0.08rem;
  width: 0.8rem;
}
</style>
